import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Avatar from '../components/Avatar'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomAvatar from '../lib/generate/avatar'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const images = [
  'http://dc28c2r6oodom.cloudfront.net/avatars/0.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/2.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/3.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/5.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/8.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/6.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/4.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/9.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/11.jpg',
  'http://dc28c2r6oodom.cloudfront.net/avatars/10.jpg'
]

const Main = ({ avatar }) => (
  <div
    css={{
      display: 'block',
      minHeight: '16rem',
      height: '100%'
    }}
  >
    <div
      css={{
        display: 'block',
        width: '100%',
        paddingTop: 96,
        paddingLeft: 64
      }}
    >
      <Div width="auto" display="inline-block">
        <Avatar avatar={avatar} image={avatar.image} />
      </Div>
      <div css={{ marginTop: 48, display: 'flex', alignItems: 'center' }}>
        <Avatar avatar={avatar} image={images[6]} />
        <Div ml={2}>
          <H4 my={0} fontSize={0}>
            Dean Blacc
          </H4>
          <P fontSize={0} my={0}>
            London, England
          </P>
        </Div>
      </div>
      <div
        style={{
          background: 'yellow',
          marginTop: 64,
          position: 'relative',
          display: 'block',
          width: 'auto'
        }}
      >
        {images.map((image, i) => (
          <div
            key={image * i}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: (i * avatar.size) / (avatar.spread / 100) + 'px'
            }}
          >
            <Avatar avatar={avatar} image={image} />
          </div>
        ))}
      </div>
    </div>
  </div>
)

const GridItem = ({ avatar, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '19vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <Avatar avatar={avatar} image={images[6]} />
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseAvatar = randomAvatar()
        const avatarWithPins = { ...baseAvatar, ...pins }
        const avatar = {
          ...avatarWithPins,
          backgroundImage: 'https://mrmrs.cc/photos/u/001.jpg' //randomAvatar.toBackgroundImage(gradientWithPins)
        }

        return (
          <GridItem key={i} onClick={() => onSelect(avatar)} avatar={avatar} />
        )
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Avatar</SidebarHeading>
      <H5 fontSize={0} fontWeight={500}>
        Wrapper
      </H5>
      <Color
        label="Color"
        value={value.color}
        onChange={changeValue('color')}
        active={pins.color}
        onLock={() => onPin('color', value.color)}
        width={128}
      />
      <Color
        label="Background"
        value={value.bg}
        onChange={changeValue('bg')}
        active={pins.bg}
        onLock={() => onPin('bg', value.bg)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadow}
          onClick={() => onPin('boxShadow', value.boxShadow)}
        />
        <Label>Box Shadow</Label>
        <Input
          width={1}
          value={value.boxShadow}
          type="text"
          onChange={changeValue('boxShadow')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.size}
          onClick={() => onPin('size', value.size)}
        />
        <Label>Size</Label>
        <Input
          min={10}
          max={512}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.size)}
          type="range"
          onChange={changeValue('size')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.size}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.padding}
          onClick={() => onPin('padding', value.padding)}
        />
        <Label>Padding</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.padding)}
          type="range"
          onChange={changeValue('padding')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontpadding={0}
          children={value.padding}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.outerBorderWidth}
          onClick={() => onPin('outerBorderWidth', value.outerBorderWidth)}
        />
        <Label>Border Width</Label>
        <Input
          min={0}
          max={48}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.outerBorderWidth)}
          type="range"
          onChange={changeValue('outerBorderWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.outerBorderWidth}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.outerBorderStyle}
          onClick={() => onPin('outerBorderStyle', value.outerBorderStyle)}
        />
        <Label>Border Style</Label>
        <select
          value={value.outerBorderStyle}
          onChange={changeValue('outerBorderStyle')}
        >
          <option>solid</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>double</option>
          <option>groove</option>
          <option>ridge</option>
          <option>inset</option>
          <option>outset</option>
        </select>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.outerTopRightRadius}
            onClick={() =>
              onPin('outerTopRightRadius', value.outerTopRightRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌜
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerTopRightRadius)}
            type="range"
            onChange={changeValue('outerTopRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerTopRightRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.outerMirrorTopRightRadius}
            onClick={() =>
              onPin(
                'outerMirrorTopRightRadius',
                value.outerMirrorTopRightRadius
              )
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerMirrorTopRightRadius)}
            type="range"
            onChange={changeValue('outerMirrorTopRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerMirrorTopRightRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.outerTopLeftRadius}
            onClick={() =>
              onPin('outerTopLeftRadius', value.outerTopLeftRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌝
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerTopLeftRadius)}
            type="range"
            onChange={changeValue('outerTopLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerTopLeftRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.outerMirrorTopLeftRadius}
            onClick={() =>
              onPin('outerMirrorTopLeftRadius', value.outerMirrorTopLeftRadius)
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerMirrorTopLeftRadius)}
            type="range"
            onChange={changeValue('outerMirrorTopLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerMirrorTopLeftRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.outerBottomLeftRadius}
            onClick={() =>
              onPin('outerBottomLeftRadius', value.outerBottomLeftRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌞
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerBottomLeftRadius)}
            type="range"
            onChange={changeValue('outerBottomLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerBottomLeftRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.outerMirrorBottomLeftRadius}
            onClick={() =>
              onPin(
                'outerMirrorBottomLeftRadius',
                value.outerMirrorBottomLeftRadius
              )
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerMirrorBottomLeftRadius)}
            type="range"
            onChange={changeValue('outerMirrorBottomLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerMirrorBottomLeftRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.outerBottomRightRadius}
            onClick={() =>
              onPin('outerBottomRightRadius', value.outerBottomRightRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌟
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerBottomRightRadius)}
            type="range"
            onChange={changeValue('outerBottomRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerBottomRightRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.outerMirrorBottomRightRadius}
            onClick={() =>
              onPin(
                'outerMirrorBottomRightRadius',
                value.outerMirrorBottomRightRadius
              )
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.outerMirrorBottomRightRadius)}
            type="range"
            onChange={changeValue('outerMirrorBottomRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.outerMirrorBottomRightRadius}
          />
        </Flex>
      </Flex>

      <H5 fontSize={0} fontWeight={500}>
        Image
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.image}
          onClick={() => onPin('image', value.image)}
        />
        <Label>Image</Label>
        <Input
          width={1}
          value={value.image}
          type="text"
          onChange={changeValue('image')}
        />
      </Flex>

      <Color
        value={value.borderColor}
        onChange={changeValue('borderColor')}
        active={pins.borderColor}
        onLock={() => onPin('borderColor', value.borderColor)}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderWidth}
          onClick={() => onPin('borderWidth', value.borderWidth)}
        />
        <Label>Border Width</Label>
        <Input
          min={0}
          max={256}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderWidth)}
          type="range"
          onChange={changeValue('borderWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderWidth}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderStyle}
          onClick={() => onPin('borderStyle', value.borderStyle)}
        />
        <Label>Border Style</Label>
        <select value={value.borderStyle} onChange={changeValue('borderStyle')}>
          <option>solid</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>double</option>
          <option>groove</option>
          <option>ridge</option>
          <option>inset</option>
          <option>outset</option>
        </select>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.innerTopRightRadius}
            onClick={() =>
              onPin('innerTopRightRadius', value.innerTopRightRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌜
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerTopRightRadius)}
            type="range"
            onChange={changeValue('innerTopRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerTopRightRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.innerMirrorTopRightRadius}
            onClick={() =>
              onPin(
                'innerMirrorTopRightRadius',
                value.innerMirrorTopRightRadius
              )
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerMirrorTopRightRadius)}
            type="range"
            onChange={changeValue('innerMirrorTopRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerMirrorTopRightRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.innerTopLeftRadius}
            onClick={() =>
              onPin('innerTopLeftRadius', value.innerTopLeftRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌝
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerTopLeftRadius)}
            type="range"
            onChange={changeValue('innerTopLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerTopLeftRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.innerMirrorTopLeftRadius}
            onClick={() =>
              onPin('innerMirrorTopLeftRadius', value.innerMirrorTopLeftRadius)
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerMirrorTopLeftRadius)}
            type="range"
            onChange={changeValue('innerMirrorTopLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerMirrorTopLeftRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.innerBottomLeftRadius}
            onClick={() =>
              onPin('innerBottomLeftRadius', value.innerBottomLeftRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌞
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerBottomLeftRadius)}
            type="range"
            onChange={changeValue('innerBottomLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerBottomLeftRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.innerMirrorBottomLeftRadius}
            onClick={() =>
              onPin(
                'innerMirrorBottomLeftRadius',
                value.innerMirrorBottomLeftRadius
              )
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerMirrorBottomLeftRadius)}
            type="range"
            onChange={changeValue('innerMirrorBottomLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerMirrorBottomLeftRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.innerBottomRightRadius}
            onClick={() =>
              onPin('innerBottomRightRadius', value.innerBottomRightRadius)
            }
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌟
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerBottomRightRadius)}
            type="range"
            onChange={changeValue('innerBottomRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerBottomRightRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.innerMirrorBottomRightRadius}
            onClick={() =>
              onPin(
                'innerMirrorBottomRightRadius',
                value.innerMirrorBottomRightRadius
              )
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.innerMirrorBottomRightRadius)}
            type="range"
            onChange={changeValue('innerMirrorBottomRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.innerMirrorBottomRightRadius}
          />
        </Flex>
      </Flex>

      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spread}
          onClick={() => onPin('spread', value.spread)}
        />
        <Label>Overlap</Label>
        <Input
          min={80}
          max={400}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.spread)}
          type="range"
          onChange={changeValue('spread')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontsize={0}
          children={value.spread}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import React from 'react'\n\n" +
          'const Avatar = ({ avatar, ...props }) => {\n' +
          'return (\n' +
          '<div css={{\n' +
          `color: '${value.color}',\n` +
          `maxWidth: '${value.size +
            value.outerBorderWidth +
            value.padding}px',\n` +
          `backgroundColor: '${value.bg}',\n` +
          `height: 'auto',\n` +
          `width: 'auto',\n` +
          `borderWidth: '${value.outerBorderWidth}px',\n` +
          `borderStyle: '${value.outerBorderStyle}',\n` +
          `borderColor: 'currentColor',\n` +
          `boxShadow: value.boxShadow,\n` +
          `padding: value.padding + 'px',\n` +
          `borderRadius: ${value.outerTopRightRadius}% ${value.outerTopLeftRadius}% ${value.outerBottomRightRadius}% ${value.outerBottomLeftRadius}% / ${value.outerMirrorTopRightRadius}% ${value.outerMirrorTopLeftRadius}% ${value.outerMirrorBottomRightRadius}% ${value.outerMirrorBottomLeftRadius}%\n` +
          '}} {...props} >\n' +
          '<img src={props.image}\n' +
          'css={{\n' +
          `position: 'relative',\n` +
          `width: '${value.size}px',\n` +
          `height: '${value.size}px',\n` +
          `display: 'block',\n` +
          `borderWidth: '${value.borderWidth}px',\n` +
          `borderStyle: '${value.borderStyle}',\n` +
          `borderColor: '${value.borderColor}',\n` +
          `borderRadius: '${value.innerTopRightRadius}% ${value.innerTopLeftRadius}% ${value.innerBottomRightRadius}% ${value.innerBottomLeftRadius}% / ${value.innerMirrorTopRightRadius}% ${value.innerMirrorTopLeftRadius}% ${value.innerMirrorBottomRightRadius}% ${value.innerMirrorBottomLeftRadius}%'\n` +
          '}}\n' +
          '/>\n' +
          '</div>\n' +
          ')\n' +
          '}\n\n' +
          'Avatar.defaultProps = {}\n\n' +
          'export default Avatar'
        }
      />
    </>
  )
}

export default ({ initialAvatar, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('avatarVotes'))
  } catch (e) {}

  const [avatar, setAvatar] = useState(initialAvatar || randomAvatar())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const avatarWithPins = { ...avatar, ...pins }
  const fullAvatar = {
    ...avatarWithPins,
    backgroundColor: 'red', //gradientWithPins.startColor,
    backgroundImage: 'https://components-ai.s3.amazonaws.com/avatars/0.jpg' //randomAvatar.toBackgroundImage(gradientWithPins)
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('avatars')
      .collection('votes')
      .add({
        up: true,
        data: fullAvatar,
        metadata: { pins }
      })

    localStorage.setItem(
      'avatarVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setAvatar(randomAvatar())
  }
  const skip = () => {
    setAvatar(randomAvatar())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      return
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = avatar => {
    setAvatar(avatar)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main avatar={fullAvatar} />
        )
      }
      sidebar={
        <Sidebar
          value={fullAvatar}
          pins={pins}
          onChange={(key, value) => {
            setAvatar({
              ...avatar,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <Flex>
            <VoteButton width="50%" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton width="50%" onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
