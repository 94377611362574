const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

/*
 
  size: [width, height]

  outer borderRadius 
        -right
        -left
        -bottom
        -top

  inner borderRadius 

  outer border style
  outer border width
  outer border color

  inner border style
  inner border width
  inner border color

  wrapper bg
  wrapper padding
  wrapper boxShadow

  avatarImg
  
  

*/

const borderStyles = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]
const generateAvatar = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const outerRadius = randomInt(0, 100)

  const radiusBool = randomInt(0, 10)

  let outerRadiusMax = outerRadius
  let outerRadiusMin = outerRadius
  let innerRadiusMax = outerRadius
  let innerRadiusMin = outerRadius

  if (radiusBool > 5) {
    outerRadiusMax = randomInt(60, 100)
    outerRadiusMin = randomInt(10, 60)
    innerRadiusMax = randomInt(50, 100)
    innerRadiusMin = randomInt(10, 60)
  }

  const borderWidth = randomInt(0, 4)
  const outerBorderWidth = randomInt(0, 6)

  const size = randomInt(32, 80)
  const padding = randomInt(0, 8)

  const borderStyle = sample(borderStyles)

  const images = [
    'http://dc28c2r6oodom.cloudfront.net/avatars/0.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/2.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/3.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/5.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/8.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/6.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/4.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/9.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/11.jpg',
    'http://dc28c2r6oodom.cloudfront.net/avatars/10.jpg'
  ]
  const image = sample(images)

  const avatar = {
    color: color, // set color for wrapper border
    borderColor: color, // set border color for image
    borderStyle: borderStyle,
    outerBorderStyle: borderStyle,
    bg: 'transparent', // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    spread: 80,
    size: size,
    borderWidth: borderWidth,
    outerBorderWidth: borderWidth > 0 ? 0 : outerBorderWidth,
    padding: borderWidth > 0 ? 0 : padding,
    outerTopRightRadius: outerRadiusMin,
    outerTopLeftRadius: outerRadiusMax,
    outerBottomRightRadius: outerRadiusMax,
    outerBottomLeftRadius: outerRadiusMin,
    outerMirrorTopRightRadius: outerRadiusMax,
    outerMirrorTopLeftRadius: outerRadiusMin,
    outerMirrorBottomRightRadius: outerRadiusMin,
    outerMirrorBottomLeftRadius: outerRadiusMax,
    innerTopRightRadius: outerRadiusMax - 2,
    innerTopLeftRadius: outerRadiusMax - 2,
    innerBottomRightRadius: outerRadiusMin - 2,
    innerBottomLeftRadius: outerRadiusMin - 2,
    innerMirrorTopRightRadius: outerRadiusMin - 2,
    innerMirrorTopLeftRadius: outerRadiusMax - 2,
    innerMirrorBottomRightRadius: outerRadiusMax - 2,
    innerMirrorBottomLeftRadius: outerRadiusMin - 2,
    boxShadow: boxShadow,
    image: image
  }

  return {
    ...avatar
  }
}

module.exports = generateAvatar
